import React, { useState, useRef, useEffect, Suspense } from 'react';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Environment } from '@react-three/drei';
import { TextureLoader } from 'three';
import * as THREE from 'three';
import gsap from 'gsap';
import { FBXLoader } from 'three-stdlib';

const PHOTOS = [
  '/photos/1690_24.jpg',
  '/photos/1691_16.jpg'
];

const Desk = () => {
  return (
    <group position={[0, 1.03, 0.5]}>
      {/* Desktop surface */}
      <mesh position={[0, 0, 0.2]}>
        <boxGeometry args={[3, 0.1, 1.5]} />
        <meshStandardMaterial color="#96735d" />
      </mesh>
      {/* Desk legs */}
      <mesh position={[-1.4, -0.55, 0.8]}>
        <boxGeometry args={[0.1, 1.1, 0.1]} />
        <meshStandardMaterial color="#96735d" />
      </mesh>
      <mesh position={[1.4, -0.55, 0.8]}>
        <boxGeometry args={[0.1, 1.1, 0.1]} />
        <meshStandardMaterial color="#96735d" />
      </mesh>
      <mesh position={[-1.4, -0.55, -0.4]}>
        <boxGeometry args={[0.1, 1.1, 0.1]} />
        <meshStandardMaterial color="#96735d" />
      </mesh>
      <mesh position={[1.4, -0.55, -0.4]}>
        <boxGeometry args={[0.1, 1.1, 0.1]} />
        <meshStandardMaterial color="#96735d" />
      </mesh>
    </group>
  );
};

const Walls = () => {
  return (
    <group>
      {/* Back wall */}
      <mesh position={[0, 2, 0]}>
        <planeGeometry args={[8, 4, 2]} />
        <meshStandardMaterial color="#e0e0e0" />
      </mesh>
      {/* Floor */}
      <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, 0, 2]}>
        <planeGeometry args={[8, 4]} />
        <meshStandardMaterial color="#cccccc" />
      </mesh>
    </group>
  );
};

const Laptop = () => {
  return (
    <group position={[0, 1.1, 0.5]}>
      {/* Base */}
      <mesh>
        <boxGeometry args={[1, 0.05, 0.8]} />
        <meshStandardMaterial color="#2c3e50" />
      </mesh>
      {/* Screen */}
      <mesh position={[0, 0.3, -0.3]} rotation={[Math.PI / 6, 0, 0]}>
        <boxGeometry args={[0.9, 0.5, 0.02]} />
        <meshStandardMaterial color="#34495e" />
      </mesh>
    </group>
  );
};

const InteractivePhotoFrame = ({ onPhotoClick, currentPhotoIndex, isZoomed }) => {
  const texture = useLoader(
    TextureLoader, 
    PHOTOS[currentPhotoIndex],
    undefined,
    (error) => console.error('Error loading texture:', error)
  );

  return (
    <mesh 
      position={[1.8, 2.2, 0]} 
      onClick={onPhotoClick}
    >
      <boxGeometry args={[1.5, 1.2, 0.05]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
};

const CameraController = ({ isZoomed, framePosition }) => {
  const { camera, gl } = useThree();
  const controlsRef = useRef();

  useEffect(() => {
    if (isZoomed) {
      // Zoom to the photo frame
      const target = new THREE.Vector3(...framePosition);
      
      // Animate camera position
      gsap.to(camera.position, {
        x: target.x,
        y: target.y + 1,
        z: target.z + 2,
        duration: 0.5,
        ease: 'power2.inOut'
      });

      // Animate camera look at
      gsap.to(controlsRef.current.target, {
        x: target.x,
        y: target.y,
        z: target.z,
        duration: 0.5,
        ease: 'power2.inOut',
        onUpdate: () => {
          controlsRef.current.update();
        }
      });
    } else {
      // Reset camera to original position
      gsap.to(camera.position, {
        x: 0,
        y: 3,
        z: 5,
        duration: 0.5,
        ease: 'power2.inOut'
      });

      gsap.to(controlsRef.current.target, {
        x: 0,
        y: 0,
        z: 0,
        duration: 0.5,
        ease: 'power2.inOut',
        onUpdate: () => {
          controlsRef.current.update();
        }
      });
    }
  }, [isZoomed]);

  return (
    <OrbitControls 
      ref={controlsRef}
      enablePan={!isZoomed} 
      enableZoom={!isZoomed} 
      enableRotate={!isZoomed}
      minPolarAngle={0} 
      maxPolarAngle={Math.PI / 2} 
      minAzimuthAngle={-Math.PI/2} 
      maxAzimuthAngle={Math.PI/2}
    />
  );
};

const FBXModel = ({ url, position, scale }) => {
  const [model, setModel] = useState();

  useEffect(() => {
    const loader = new FBXLoader();
    loader.load(
      url,
      (fbx) => {
        fbx.scale.set(scale, scale, scale);
        fbx.position.set(...position);
        setModel(fbx);
      },
      undefined,
      (error) => console.error('FBX loading error:', error)
    );
  }, [url, position, scale]);

  return model ? <primitive object={model} /> : null;
};

const WelcomeOverlay = ({ isZoomed }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (isZoomed) {
      setIsVisible(false);
    }
  }, [isZoomed]);

  if (!isVisible) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      zIndex: 10,
      fontFamily: 'Arial, sans-serif',
      pointerEvents: 'none'
    }}>
      <div style={{
        textAlign: 'center',
        padding: '2rem',
        borderRadius: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        maxWidth: '500px'
      }}>
        <h1 style={{ 
          fontSize: '2rem', 
          marginBottom: '1rem' 
        }}>
          Welcome to My Work in Progress Website
        </h1>
        <p style={{ 
          fontSize: '1.2rem',
          marginBottom: '1.5rem'
        }}>
          Try clicking on the photo on the wall to explore!
        </p>
        <button 
          onClick={() => setIsVisible(false)}
          style={{
            backgroundColor: 'white',
            color: 'black',
            border: 'none',
            padding: '0.5rem 1rem',
            borderRadius: '5px',
            cursor: 'pointer',
            pointerEvents: 'auto'
          }}
        >
          Got it!
        </button>
      </div>
    </div>
  );
};

export default function OfficeScene() {
  const [isZoomed, setIsZoomed] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const framePosition = [1.8, 2.2, 0]; // Same as frame position in InteractivePhotoFrame
  const handlePhotoClick = () => setIsZoomed(true);

  return (
    <>
      <Canvas style={{ width: '100%', height: '100%' }}>
        <PerspectiveCamera makeDefault position={[0, 3, 5]} />
        <CameraController 
          isZoomed={isZoomed} 
          framePosition={framePosition} 
        />
        <ambientLight intensity={0.8} />
        <spotLight 
          position={[1.8, 3.5, 2]} // Positioned near the photo frame
          penumbra={0.8} 
          angle={0.1}
          intensity={3} // Increased intensity
        />

        <Walls />
        <Laptop />
        <InteractivePhotoFrame 
          onPhotoClick={handlePhotoClick}
          currentPhotoIndex={currentPhotoIndex}
          isZoomed={isZoomed}
        />
        <Suspense fallback={null}>
          <FBXModel 
            url="/models/Desk.fbx"
            position={[0, 0.8, 0.8]}
            scale={0.007} 
          />
        </Suspense>
      </Canvas>

      <WelcomeOverlay isZoomed={isZoomed} />

      <PhotoNavigationUI
        currentPhotoIndex={currentPhotoIndex}
        totalPhotos={PHOTOS.length}
        onNext={() => setCurrentPhotoIndex((prev) => (prev + 1) % PHOTOS.length)}
        onPrev={() => setCurrentPhotoIndex((prev) => (prev - 1 + PHOTOS.length) % PHOTOS.length)}
        onClose={() => setIsZoomed(false)}
        isZoomed={isZoomed}
        photos={PHOTOS}
      />
    </>
  );
}

const PhotoNavigationUI = ({ 
  currentPhotoIndex, 
  totalPhotos, 
  onNext, 
  onPrev, 
  onClose, 
  isZoomed,
  photos
}) => {
  if (!isZoomed) return null;

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      pointerEvents: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      padding: '0 2rem'
    }}>
      <button 
        onClick={onPrev}
        style={{
          pointerEvents: 'auto',
          padding: '1rem',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
      >
        ←
      </button>
      
      {/* Current photo display */}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '60%',
        maxHeight: '80%'
      }}>
        <img 
          src={photos[currentPhotoIndex]} 
          alt={`Photo ${currentPhotoIndex + 1}`}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            objectFit: 'contain',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
          }}
        />
        <p style={{
          color: 'white',
          marginTop: '1rem',
          fontSize: '1rem'
        }}>
          {currentPhotoIndex + 1} / {totalPhotos}
        </p>
      </div>
      
      <button
        onClick={onClose}
        style={{
          position: 'absolute',
          top: '2rem',
          right: '2rem',
          pointerEvents: 'auto',
          padding: '0.5rem 1rem',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        ✕
      </button>

      <button
        onClick={onNext}
        style={{
          pointerEvents: 'auto',
          padding: '1rem',
          backgroundColor: 'white',
          border: 'none',
          borderRadius: '50%',
          cursor: 'pointer'
        }}
      >
        →
      </button>
    </div>
  );
};